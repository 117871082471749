import { HomePage } from "./HomePage";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

function MyRouter() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Redirect push to={"/"} />
      </Switch>
    </Router>
  );
}

export default MyRouter;
