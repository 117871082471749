import "./HomePage.css";
import logo from "./assets/logo.png";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BsChatQuoteFill, BsInfoCircle } from "react-icons/bs";
import { FiPhoneCall } from "react-icons/fi";
import { GiHealing, GiTreeDoor } from "react-icons/gi";

function HomePage() {
  const [ready, setReady] = useState(false);

  return (
    <div className="MainPage">
      <TopSection />
      <InfoText />
    </div>
  );
}

function TopSection(props) {
  return (
    <>
      <TopBanner />
      <NavigationBar />
    </>
  );
}

function NavigationBar(props) {
  return (
    <nav id="navbar">
      <a href="#health" className="navbtn">
        HEALTH BENEFITS
      </a>
      <a href="#sunnah" className="navbtn">
        SUNNAH
      </a>
      <a href="#about" className="navbtn">
        ABOUT US
      </a>
      <a href="#testimonial" className="navbtn">
        TESTIMONIALS
      </a>
      <a href="#contact" className="navbtn">
        CONTACT US
      </a>
    </nav>
  );
}

function IntroHealingCard(props) {
  const initialBenefitsText = (
    <span>
      Hijama, also known as wet cupping, is a practice recommended by the
      Prophet. It is a method of extracting the toxic and stagnant blood from
      parts of the body by creating a vacuum of air for suction with a cup.
      Hijama is a treatment that can help with curing and preventing illnesses
      throughout the body.
      <br />
      <br />
    </span>
  );

  const expandedBenefitsText = (
    <span>
      {initialBenefitsText}
      Hijama is a sunnah of the Prophet (pbuh) and has many benefits both
      spiritually and physically. It is an excellent form of preventative and
      curative therapy. It's benefits include
      <ul>
        <li>Boosting the circulatory and immune systems</li>
        <li>Cleansing and detoxifying the body</li>
        <li>Helping treat illnesses</li>
        <li>Relaxing the patient</li>
        <li>Providing general pain relief</li>
      </ul>
      Hijama Cupping Therapy essentially detoxifies and cleanses the body, by
      drawing toxic blood from the body. This produces new blood cells and
      strengthens the immune system. Cupping is also useful to treat muscular
      and skeletal issues. Some other benefits that may be seen with cupping are
      <ul>
        <li>Improved memory</li>
        <li>Better eyesight</li>
        <li>Pain relief</li>
        <li>Improved circulatory and immune system</li>
        <li>Reduced headaches</li>
        <li>Less fatigue</li>
        <li>Improved skin</li>
        <li>Less back pain</li>
      </ul>
    </span>
  );

  const [benefitsText, setBenefitsText] = useState(initialBenefitsText);
  const [showLink, setShowLink] = useState(true);

  function openAllText() {
    if (showLink) {
      setBenefitsText(expandedBenefitsText);
      setShowLink(false);

      const element = document.getElementById("health");
      if (element) element.scrollIntoView();
    } else {
      setBenefitsText(initialBenefitsText);
      setShowLink(true);

      const element = document.getElementById("health");
      if (element) element.scrollIntoView();
    }
  }

  const readMoreButton = (
    <span className="linked-url" onClick={openAllText}>
      {showLink ? "Learn More..." : "Show Less..."}
    </span>
  );

  return (
    <div className="info-card">
      <h1 className="card" id="health">
        Healing
        <span className="react-icons">
          <GiHealing />
        </span>
      </h1>
      <span className="card-body">
        {benefitsText}
        {readMoreButton}
      </span>
      <span className="card-caption">Mind, Body, Soul</span>
    </div>
  );
}

function InfoText(props) {
  return (
    <div id="info-text">
      <IntroHealingCard />
      <SunnahCard />
      <AboutCard />
      <TestimonialsCard />
      <ContactCard />
    </div>
  );
}

function SunnahCard(props) {
  return (
    <div className="info-card">
      <h1 className="card" id="sunnah">
        Sunnah
        <span className="react-icons">
          <GiTreeDoor />
        </span>
      </h1>
      <span className="card-body">
        <span className="hadith">
          Anas ibn Maalik (r) reported that the Messenger (Peace Be Upon Him)
          said, "Indeed the best of remedies you have is Hijama (Cupping)…"
        </span>
        <span className="hadith" id="odd-section-text">
          the Prophet (peace and blessings of Allaah be upon him) said: “I did
          not pass by any group on the night when I was taken on the Night
          Journey (Isra’), but they said to me, ‘O Muhammad, tell your ummah to
          do cupping.'
        </span>
        <span className="hadith">
          Salma, the servant of the Messenger (PBUH) said, "Whenever someone
          would complain of a headache to the Messenger of Allah (saw), he (saw)
          would advise them to perform Hijama (Cupping)."
        </span>
      </span>
      <span className="card-caption">
        Revive the sunnah of our Prophet (pbuh), schedule a cupping session
        today
      </span>
    </div>
  );
}

function TestimonialsCard(props) {
  return (
    <div className="info-card">
      <h1 className="card" id="testimonial">
        Testimonials
        <span className="react-icons">
          <BsChatQuoteFill />
        </span>
      </h1>
      <span className="card-body">
        <span className="testimonial">
          "This was an amazing experience Alhamdulilaah. I was a little
          nervous—as this was my first time getting Hijama, but the sister made
          me feel so comfortable and at ease. Everything was done nicely and by
          the Sunnah, and she went above and beyond to make sure this was an
          enjoyable and healthy experience. Highly recommend her!"
        </span>
        <span className="testimonial" id="odd-section-text">
          "Serap is an excellent hijama therapist. Very professional, very clean
          and sterile work environment. Made me feel comfortable and helped so
          much of my Muscle tightness!! I Highly Recommend!""
        </span>
        <span className="testimonial">
          "She is the best at her job. She cares about her patients and does
          more than hijama. She is very clean, very skillful, has many years of
          experience, and spends more time on her patients than any other
          practioners. İ am her patient for many years and highly recommend
          her."
        </span>
      </span>
      <span className="card-caption">
        Find more reviews from my Google Business Page{" "}
        <a
          className="hijama-link"
          href="https://maps.app.goo.gl/ztbP7oen4rLHdhJg7"
        >
          here{" "}
        </a>
      </span>
    </div>
  );
}

function AboutCard(props) {
  return (
    <div className="info-card">
      <h1 className="card">
        <span id="about">About Us</span>
        <span className="react-icons">
          <BsInfoCircle />
        </span>
      </h1>
      <span className="card-body">
        I am a certified Hijama practitioner and have an accredited higher
        diploma in hijama cupping therapy from{" "}
        <a className="hijama-link" href="https://hijamanation.com/">
          Hijama Nation.
        </a>{" "}
        I have been practicing cupping for nearly 10 years. Alongside cupping I am also
        certified in leeching therapy.
        <br />
        <br />
        You can find my Google Business Page and Reviews{" "}
        <a
          className="hijama-link"
          href="https://maps.app.goo.gl/ztbP7oen4rLHdhJg7"
        >
          here
        </a>
        <br />
        <br />
        I have practiced Hijama in the United States for several years, providing services at Columbia, MD and the Chaska, MN area. Currently due to location, I am providing services in the Uskudar, Turkey region.
        <br />
        <br />
        You can contact me through my phone number <span id="phone-number">+90 546 904 7951</span> or at
        <span id="email">hijamasister.contact@gmail.com</span>
      </span>
      <span className="card-caption">
        Reach out to me and let me know you're interested!
      </span>
    </div>
  );
}

function AboutMePage(props) {
  return (
    <div className="MainPage">
      <TopSection />
      <div id="info-text">
        <InfoCard
          header="About Me"
          headerID="aboutme"
          text={`Hijama is a sunnah of the Prophet (pbuh) and has many benefits both spiritually and physically. It is an excellent form of preventative and curative therapy. It's benefits include

Boosting the circulatory and immune systems
Cleansing and detoxifying the body
Helping treat illnesses
Relaxing the patient
Providing general pain relief

Hijama Cupping Therapy essentially detoxifies and cleanses the body, by drawing toxic blood from the body. This produces new blood cells and strengthens the immune system. Cupping is also useful to treat muscular and skeletal issues.

Some other benefits that may be seen with cupping:

Improved memory
Better eyesight
Pain relief
Improved circulatory and immune system
Reduced headaches
Less fatigue
Improved skin
Less back pain`}
        // caption_text="Mind, Body, Soul"
        />
        <ContactCard />
      </div>
    </div>
  );
}

function ContactUsPage(props) {
  return (
    <div className="MainPage">
      <TopSection />
      <div id="info-text">
        <ContactCard />
      </div>
    </div>
  );
}

function ContactCard(props) {
  return (
    <div className="info-card">
      <h1 className="card" id="contact">
        Contact Us
        <span className="react-icons">
          <FiPhoneCall />
        </span>
      </h1>
      <span className="card-body">
        Currently serving from the Uskudar, Turkey area! To set up an appointment or
        get in contact, reach me at:{" "}
        <span id="phone-number"> +90 546 904 7951</span> or at
        <span id="email"> hijamasister.contact@gmail.com</span>
      </span>

      <AddressMap />
      {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d48152.69140962616!2d29.047789549999997!3d41.035248499999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7dfe4135c19ef%3A0xd02695d68c8d2a7f!2sHijamasister!5e0!3m2!1sen!2sus!4v1715980079823!5m2!1sen!2sus" width="800" height="600" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}

      <span className="card-caption">
        Cupping therapy for the mind, body, and soul
      </span>
    </div>
  );
}

const AddressMap = () => {
  return (
    <div className="google-map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d48152.69140962616!2d29.047789549999997!3d41.035248499999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7dfe4135c19ef%3A0xd02695d68c8d2a7f!2sHijamasister!5e0!3m2!1sen!2sus!4v1715980079823!5m2!1sen!2sus"
        width="600"
        height="450"
        frameborder="0"
        style={{ border: 0 }}
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
      />
    </div>
  );
};


function InfoCard(props) {
  const headerID = (innertext) => {
    return <span id={props.headerID}>{innertext}</span>;
  };
  return (
    <div className="info-card">
      <h1 className="card">
        {props.headerID !== "" ? headerID(props.header) : props.header}
      </h1>
      <span className="card-body">{props.text}</span>
      <span className="card-caption">{props.caption_text}</span>
    </div>
  );
}

function TopBanner(props) {
  const [ready, setReady] = useState(false);

  return (
    <div id="TopBar">
      <LogoPlaceholder />
      <div id="banner-message">
        Discover the miracle of cupping therapy today
      </div>
    </div>
  );
}

function LogoPlaceholder(props) {
  const [ready, setReady] = useState(false);

  return (
    <>
      <div
        id="logo"
        style={{
          backgroundColor: "#ac85a4",
          opacity: 0.8,
        }}
        hidden={!ready ? false : true}
      ></div>
      <a href="https://hijamasister.com">
        <img
          id="logo"
          onLoad={() => setReady(true)}
          src={logo}
          hidden={ready ? false : true}
        />
      </a>
    </>
  );
}

export { HomePage };
